import React, { useEffect, useState } from "react";
import { get } from "../service/axiosService";
import { PiStudentDuotone } from "react-icons/pi";

const Education = () => {
  const [educations, setEducations] = useState([]);
  async function getEducation() {
    try {
      const { data } = await get("education");
      setEducations(data);
    } catch (error) {
      console.error("Error fetching education:", error);
    }
  }
  useEffect(() => {
    getEducation();
  }, []);
  return (
    <div className="container box-grad p-6">
      <h3 className="text-xl capitalize mb-6">education</h3>
      <div className="flex flex-col justify-center items-center ">
        <div className="border-l-2 py-8">
          {educations.map((education) => (
            <div key={education.id} className="bg-[#0c1322] flex gap-4 mb-5 items-center border-[#222F43] border p-5">
              <p className="border p-2 w-fit -translate-x-12 bg-slate-800 z-10 rounded-full">
                <PiStudentDuotone size={30} />
              </p>
              <div>
              <p className="font-bold mb-2 text-xl capitalize">{education.title}</p>
                <p className="text-base">
                    {education.university}
                </p>
              </div>
             
              <p className="ml-3">
                {education.start} - {education.end}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Education;
