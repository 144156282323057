import React from 'react'
import Icon from './../assets/images/icon .svg'
const Box = ({children}) => {
  return (
    <div className="box-grad   text-[#0ea5ea] flex gap-6 p-2 items-center 
    px-4 h-full shadow-lg ">
        {children}
        <img src={Icon} alt="" className="icon" />
    </div>
  )
}

export default Box