import { FaLinkedin, FaInstagram } from "react-icons/fa6";
import { BsWhatsapp } from "react-icons/bs";
import Logo from "./Logo";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { post } from "../service/axiosService";

const Footer = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [showError, setshowError] = useState(false)
  const [successMessage, setSetsuccessMessage] = useState(false)
  const [messageText, setMessageText] = useState("Please fill in the blank fields")
  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };
  async function handleMessage() {
    try {
        if(name ==="" || email ==="" || message ==="" ){
            setshowError(true)
            return
        }
        if(!validateEmail(email)){
            setshowError(true)
            setMessageText("Please enter a valid email address")
            return
        }
        const {data}=await post('message',{
            name,email,message
        })
        if(data.success){
          setSetsuccessMessage(true)
          setName("")
          setEmail("")
          setMessage("")
        }
    } catch (error) {
        
    }
  }
  useEffect(()=>{
    const hideMessage=setTimeout(() => {
      setSetsuccessMessage(false);
      setshowError(false)
    }, 5000);
    return () => clearTimeout(hideMessage);
  },[successMessage,showError])
  return (
    <footer className="flex mb-8 relative py-8 flex-col items-center p-12 rounded-3xl border mt-12 bg-[#121C31] border-[#222F43]">
      <div className="lg:grid grid-cols-5 gap-8  h-fit mb-8">
        <div className="col-span-2">
          <Logo />
          <p className="py-8">
            I have lots of experience in 𝑩𝒂𝒄𝒌-𝑬𝒏𝒅 development. I have a solid
            record of 𝒐𝒗𝒆𝒓 𝒔𝒊𝒙 𝒚𝒆𝒂𝒓𝒔 in Back-End programming. I am renowned for
            my exceptional Teamwork skills. I use them to drive Innovation and
            deliver Excellence in every Project.
          </p>
          <div>
            <p className="font-bold text-lg mb-2">Address</p>
            <p>Iran - Tehran</p>
          </div>
        </div>

        <div className="col-span-1 mb-4 md:mb-0">
          <h3 className="text-xl font-bold pt-4">Links</h3>
          <div className="flex flex-col gap-4 pl-4 font-bold text-[#94A9C9] my-3">
            <Link to="/">Home</Link>
            <Link to="/projects">Projects</Link>
            <Link to="/skills">Skills</Link>
            <Link to="/certificate">certificate</Link>
          </div>
        </div>

        <div className="col-span-2">
          <p className="text-xl text-[#0ea5ea]">Contact Us</p>
          <p>
            Your email address will not be published. Required fields are marked
            *
          </p>

          <br />
          <input
            onChange={(e) => setName(e.target.value)}
            placeholder="Your Name *"
            value={name}
            type="text"
            className="w-full mb-3 form-control bg-transparent border h-12 border-input"
          />
          <input
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Your Email *"
            value={email}
            type="text"
            className="w-full mb-3 form-control bg-transparent border h-12 border-input"
          />
          <textarea
            placeholder="Message *"
            onChange={(e) => setMessage(e.target.value)}
            value={message}
            className="w-full mb-3 form-control bg-transparent border h-20 border-input"
          >
          
          </textarea>
          <div className="flex items-center justify-between">
            <button  onClick={handleMessage} className="btn-linear  h-8 px-3 rounded-md">
              Send Message
            </button>
           {showError && ( <p className="text-rose-400">
            {messageText}
           </p>)}
           {successMessage && (<p className="text-emerald-500">Send Message Successfully!</p>)}
          </div>
        </div>
        {/* <div className='absolute -bottom-6 w-48 h-48 left-0' >
        <img src='/images/footage1.png' alt=''/>
      </div> */}
      </div>
      <div className="border-t-2 p-4 pt-8 flex flex-col md:flex-row gap-3 justify-between w-full border-[#222F43]">
        <p className="text-base">
          <span>© All rights reserved by </span>
          <span className="font-bold text-blue-500">Shahrzad</span>
        </p>

        <div className="flex text-[#94A9C9] gap-6">
          <a className="flex gap-2" target="_blank" href="https://www.instagram.com/shahrzad.mamourian">
            <FaInstagram color="#94A9C9" size={20} />
            <span>Instagram</span>
          </a>
          <a className="flex gap-2" target="_blank" href="https://www.linkedin.com/in/shahrzad-mamourian">
            <FaLinkedin color="#94A9C9" size={20} />
            <span>Linkedin</span>
          </a>
          <a className="flex gap-2" target="_blank" href="https://wa.me/09228106545">
            <BsWhatsapp color="#94A9C9" size={20} />
            <span>Whatsapp</span>
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
