import React from 'react'
import Box from '../Box';
import { Link } from "react-router-dom";
import { LuDownload } from "react-icons/lu";

const Info = ({info,m}) => {
  return (
    <Link to="/about" className="">
            <m.div
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ duration: 1.5 }}
              exit={{ scale: 0.5 }}
              className="h-full w-full"
            >
              <Box>
                <div className="flex flex-col gap-3 lg:flex-row p-2">
                  <m.img
                    initial={{ scale: 0.6 }}
                    animate={{ scale: 1 }}
                    transition={{ duration: 1 }}
                    exit={{ scale: 0 }}
                    className=" shadow-xl banner-img avatar w-48 lg:w-60 xl:w-60"
                    src={info.image_url}
                    alt=""
                  />

                  <div className=" flex flex-col items-start  gap-1 md:gap-3">
                    <p className="text-subtitle">{info.title}</p>
                    <h1 className="text-xl 
                    text-[#0ea5ea]
                    capitalize md:text-2xl xl:text-3xl font-extrabold">
                      {info.name}
                    </h1>
                    <p className="text-subtitle pr-4">{info.summary}</p>
                   <div className="flex gap-6 mt-3">
                   <a target='_blank' onClick={e=>e.stopPropagation()} className='bg-sky-600 flex items-center gap-2 
                    rounded-sm ring ring-offset ring-offset-sky-800 
                    ring-sky-500 text-[15px]  text-white font-bold p-2 whitespace-nowrap' href={info.resume_ed_url}>
                       Academic CV
                      <LuDownload size='20'/>
                      </a>
                      <a target='_blank' onClick={e=>e.stopPropagation()} className='bg-sky-600 flex items-center gap-2 
                    rounded-sm ring ring-offset ring-offset-sky-800 
                    ring-sky-500 text-[14px]  whitespace-nowrap text-white font-bold p-3' href={info.resume_url}>
                       Professional CV
                      <LuDownload size='20'/>
                      </a>
                   </div>
                  </div>
                </div>
              </Box>
            </m.div>
          </Link>
  )
}

export default Info
