import { motion as m } from "framer-motion";

const ExperinceItem = ({ exp }) => {
  return (
    <div
      
      className="mb-6"
    >
      <m.p initial={{ y: 150 }}
      animate={{ y: 0 }}
      transition={{ duration: 1 }}
      exit={{ y: 0 }} className="text-sm text-gray-500">{exp.start} - {exp.end}</m.p>

      <m.p initial={{ x: 150 }}
      animate={{ x: 0 }}
      transition={{ duration: 1.2 }}
      exit={{ x: 0 }} className="text-[#6783ff] font-bold">{exp.title}</m.p>

      <m.p initial={{ x: 150 }}
      animate={{ x: 0 }}
      transition={{ duration: 1.3 }}
      exit={{ x: 0 }} className="font-bold my-2 text-lg">{exp.company}</m.p>

      <m.p initial={{ scale: 0 }}
      animate={{ scale: 1 }}
      transition={{ duration: 1}}
      exit={{ scale: 0 }}>{exp.desc}</m.p>
    </div>
  );
};

export default ExperinceItem;
