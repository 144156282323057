import {BiChevronUp} from 'react-icons/bi'

const ScrollTop = () => {
  return (
    <button className='bg-transparent  rounded-md text-gray-400 ring-1 ring-white'>
        <BiChevronUp size={32}/>
    </button>
  )
}

export default ScrollTop