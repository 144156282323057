import { createContext, useState } from 'react';

// Create new context
export const LoadingContext = createContext();

// Create Provider to wrap app
export const LoadingContextProvider = ({ children }) => {
  const [show, setShow] = useState(false);

  return (
    <LoadingContext.Provider value={{show, setShow }}>{children}</LoadingContext.Provider>
  )
}