import "./assets/css/styles.css";

import { get } from "./service/axiosService";
import { useContext, useEffect, useRef, useState } from "react";
import Typed from "typed.js";
import { motion as m } from "framer-motion";
import Comments from "./Components/comments/Comments";
import {Info,Project,Cetificate,Article,Skill,Contacts,Education,Intrests} from './Components/Home'
import { LoadingContext } from "./contex/Context";
function App() {
  const infoEl = useRef(null);
  const [info, setInfo] = useState({});
  const [contacts, setContacts] = useState([]);
  const [sliders, setSliders] = useState([]);
  const {setShow}=useContext(LoadingContext)
  async function getData() {
    try {
      setShow(true)
      const { data } = await get('main');
      setContacts(data.contacts);
      setInfo(data.info);
      setSliders(data.sliders.map(slider => slider.title));
    } catch (error) {
      console.error('Error fetching data:', error);
    }finally{
      setShow(false)
    }
  }

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (sliders.length === 0) return;

    const infoTyped = new Typed(infoEl.current, {
      strings: sliders,
      typeSpeed: 100,
      startDelay: 2000,
      loop: true,
      backDelay: 50,
      backSpeed: 50,
      showCursor: false,
    });

    return () => {
      infoTyped.destroy();
    };
  }, [sliders]);


  return (
    <>
      <m.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 3 }}
        className="tracking-widest"
      >
        <div className="grid lg:grid-cols-2 gap-4  md:gap-8   xl:h-80">

          <Info info={info} m={m}/>

          <div className="uppercase">
            <m.div
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ duration: 1.5 }}
              exit={{ scale: 0 }}
              className="rounded-full shadow-2xl text-sm sm:text-base
               mb-4 gap-4  bg-grad  flex justify-center px-4 items-center h-16"
            >
              <p ref={infoEl}></p>
            </m.div>
            <div className="grid sm:grid-cols-2 gap-4 lg:gap-8 xl:h-72">
              <Project m={m}/>
              <Cetificate m={m}/>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-2 xl:grid-cols-4  mt-8 sm:mt-8 md:mt-12 xl:mt-20 uppercase gap-4 xl:gap-8 ">
        
          <Article m={m}/>

        <Skill m={m}/>


         <Contacts m={m} contacts={contacts}/>
        </div>



        <div className="grid  grid-cols-4 gap-8 mt-8">
          <div className="col-span-4 md:col-span-1">
            <Education m={m}/>
          </div>
          <div className="col-span-4 md:col-span-3">
            <Comments m={m}/>
          </div>
        </div>
        <div className='mt-6 '>
          <Intrests m={m}/>
        </div>
      </m.div>

    </>
  );
}

export default App;
