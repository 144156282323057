import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation,Pagination } from 'swiper/modules';
import {useRef} from "react";
import { FaAngleRight ,FaAngleLeft} from "react-icons/fa";

const Comments = ({m}) => {
    const navigationPrevRef = useRef(null)
    const navigationNextRef = useRef(null)
    
    return (
        <m.div
        initial={{ y: 150 }}
            exit={{ y: 0 }}
            animate={{ y: 1 }}
            transition={{ duration: 1.5 }}
        className='grid grid-cols-5 gap-6 md:gap-4 lg:gap-3 items-center  rounded-lg lg:h-64  box-grad'>

            <div className="col-span-5 md:col-span-3 xl:col-span-4">
                <div className='h-fit p-5'>

                    <Swiper
                        slidesPerView={3}
                        spaceBetween={30}
                        pagination={{
                            clickable: true,
                        }}
                        breakpoints={{
                            '@0.00': {
                              slidesPerView: 1,
                              spaceBetween: 10,
                            },
                            '@0.75': {
                              slidesPerView: 1,
                              spaceBetween: 20,
                            },
                            '@1.00': {
                              slidesPerView: 2,
                              spaceBetween: 30,
                            },
                            '@1.50': {
                              slidesPerView: 4,
                              spaceBetween: 30,
                            },
                          }}
                        navigation={{
                            prevEl: navigationPrevRef.current,
                            nextEl: navigationNextRef.current,
                        }}
                        modules={[Navigation,Pagination]}
                        className="mySwiper"
                    >
                        <SwiperSlide>
                            <div className="h-52  bg-grad gap-3 flex flex-col justify-center items-center p-2 ">
                                <img className='w-28 border-2 p-1 h-28 rounded-full' src="/images/avatar/avatar1.jpg" alt=""/>
                                <div className='text-center'>
                                    <p className='mb-2 text-base font-bold'>Hossein Asadi</p>
                                    <p className='text-sm'>FullStack Developer</p>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="h-52  bg-grad gap-3 flex flex-col justify-center items-center p-2 ">
                                <img className='w-28 border-2 p-1 h-28 rounded-full' src="/images/avatar/avatar2.jpg" alt=""/>
                                <div className='text-center'>
                                    <p className='mb-2 text-base font-bold'>Afra Dev</p>
                                    <p className='text-sm'>Backend Developer</p>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="h-52  bg-grad gap-3 flex flex-col justify-center items-center p-2 ">
                                <img className='w-28 border-2 p-1 h-28 rounded-full' src="/images/avatar/avatar3.jpg" alt=""/>
                                <div className='text-center'>
                                    <p className='mb-2 text-base font-bold'>Rohan </p>
                                    <p className='text-sm'>Frontend Developer</p>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="h-52  bg-grad gap-3 flex flex-col justify-center items-center p-2 ">
                                <img className='w-28 border-2 p-1 h-28 rounded-full' src="/images/avatar/avatar1.jpg" alt=""/>
                                <div className='text-center'>
                                    <p className='mb-2 text-base font-bold'>Phil Foden</p>
                                    <p className='text-sm'> UiUx</p>
                                </div>
                            </div>
                        </SwiperSlide>

                    </Swiper>
                </div>
            </div>
            <div className="col-span-5 md:col-span-2 xl:col-span-1 text-white leading-5 p-2 h-full">
                <div className=" h-full flex gap-3 justify-center items-center flex-col">
                    <img src="/images/avatar/rec.png" alt=""/>
                    {/*<VscFeedback size={30}/>*/}
                    <p className='text-center text-base text-slate-300'>
                        Recommendations
                        <br/>
                        &


                        Client Feedback
                    </p>
                    <div className="flex gap-2">
                        <button ref={navigationNextRef} className='next border border-gray-300 rounded-full p-2'>
                            <FaAngleLeft size={18}/>
                        </button>
                        <button ref={navigationPrevRef} className='prev border border-gray-300 rounded-full p-2'>

                            <FaAngleRight size={18}/>
                        </button>
                    </div>
                </div>

            </div>
        </m.div>
    )


}

export default Comments;