import React, { useState, useEffect } from 'react';

const MouseFollower = () => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = (event) => {
      setMousePosition({
        x: event.clientX,
        y: event.clientY,
      });
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  const outerCircleStyle = {
    position: 'absolute',
  
    top: `${mousePosition.y}px`,
    left: `${mousePosition.x}px`,
    width: '30px', // اندازه دایره بیرونی
    height: '30px',
    backgroundColor: 'transparent', // رنگ دایره بیرونی
    border:'1px solid #52525b',
    borderRadius: '50%',
    pointerEvents: 'none',
    transform: 'translate(-50%, -50%)',
    display: 'flex', // استفاده برای مرکز کردن دایره داخلی
    alignItems: 'center', // استفاده برای مرکز کردن دایره داخلی
    justifyContent: 'center', // استفاده برای مرکز کردن دایره داخلی
    zIndex:'1000'
  };

  const innerCircleStyle = {
    width: '7px', // اندازه دایره داخلی
    height: '7px',
    backgroundColor: '#52525b', // رنگ دایره داخلی
    borderRadius: '50%',
  };

  return (
    <div style={outerCircleStyle}>
      <div style={innerCircleStyle}></div>
    </div>
  );
};

export default MouseFollower;
