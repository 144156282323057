import axios from "axios";

axios.defaults.baseURL="https://admin.shahrzadmamourian.dev/api/"
// axios.defaults.baseURL="http://127.0.0.1:8000/api/"
axios.defaults.headers.common["Accept"] = "application/json";
export function get(url, config = {}) {
    return axios.get(url, config);
  }

  export function post(url, data, config = {}) {
    return axios.post(url, data, config);
  }
  