import Icon from "./../assets/images/icon .svg";

const ProjectItem = ({project,m}) => {
  return (
    <a href={project.url} target="_blank" rel="noreferrer">
    <m.div
    initial={{scale:0}}
    whileInView={{scale:1}}
    exit={{scale:0}}
    whileHover={{scale:1.05}}
    transition={{duration:1,type:"spring"}}
    className="box-grad p-4  w-full  flex flex-col 
    overflow-hidden
    items-center justify-center gap-1 shadow-xl  md:mb-8"
  >
    <m.img
    initial={{x:"-50%",scale:0,y:"-50%"}}
    whileInView={{x:"0",scale:1,y:"0%"}}
    exit={{x:"-50%",scale:0,y:"-50%"}}
    transition={{duration:1,delay:1}}
      className="w-full h-56 rounded-2xl  aspect-video object-cover"
      src={project.image_url}
      alt=""
    />
    <m.p initial={{scale:0}} whileInView={{scale:1}} transition={{duration:1,delay:1}} className="w-full text-left font-bold py-2 text-gray-300">{project.position}</m.p>
    <m.p initial={{scale:0}} whileInView={{scale:1}} transition={{duration:1,delay:1}} className="w-full text-left text-xl">{project.title}</m.p>
    <img src={Icon} alt="" className="icon" />
  </m.div>
  </a>
  )
}

export default ProjectItem