// import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import ArticleA from '../Components/articles/ArticleA'
import ArticleB from '../Components/articles/ArticleB'
// import { get } from '../service/axiosService'

const Article = () => {
    const {id}=useParams()
    // const [article,setArticle]=useState('')
    // useEffect(()=>{
    //  get(`article/${id}`).then(({data})=>{
    //   console.log(data);
    //  })
    // },[id])
  return (
    <div>{id==="1"?<ArticleA/>:<ArticleB/>}</div>
  )
}

export default Article