import { useEffect, useState } from 'react';
import ContactLogo from './../assets/images/contact.svg'
import { post } from '../service/axiosService';

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [showError, setshowError] = useState(false)
  const [successMessage, setSetsuccessMessage] = useState(false)
  const [messageText, setMessageText] = useState("Please fill in the blank fields")
  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };
  async function handleMessage() {
    try {
        if(name ==="" || email ==="" || message ==="" ){
            setshowError(true)
            return
        }
        if(!validateEmail(email)){
            setshowError(true)
            setMessageText("Please enter a valid email address")
            return
        }
        const {data}=await post('message',{
            name,email,message
        })
        if(data.success){
          setSetsuccessMessage(true)
          setName("")
          setEmail("")
          setMessage("")
        }
    } catch (error) {
        
    }
  }
  useEffect(()=>{
    const hideMessage=setTimeout(() => {
      setSetsuccessMessage(false);
      setshowError(false)
    }, 5000);
    return () => clearTimeout(hideMessage);
  },[successMessage,showError])
  return (
    <div className='container relative'>
        <div className="grid md:grid-cols-2 gap-8">
       <div className="">
       <h1 className='text-xl border-b pb-1 pr-8 rounded-sm w-fit mb-2'>Contact Us</h1>
        <form action="" className='frm'>
            <label htmlFor="">name:</label>
            <input value={name} onChange={e=>setName(e.target.value)} type="text" />
            <br />
            <label htmlFor="">email:</label>
            <input value={email} onChange={e=>setEmail(e.target.value)} type="email" />
            <br />
            <label htmlFor="">message:</label>
            <textarea value={message} onChange={e=>setMessage(e.target.value)} rows="8" ></textarea>
            <div className="flex flex-col gap-4">
            <button onClick={handleMessage} type='button' className='block  w-full  xl:w-8/12 h-16 bg-indigo-600 text-white rounded-sm font-bold'>Send Message</button>
           {showError && ( <p className="text-rose-400">
            {messageText}
           </p>)}
           {successMessage && (<p className="text-emerald-500">Send Message Successfully!</p>)}
          </div>
          
        </form>
       </div>

        <div className="hidden md:flex justify-center items-center">
            <img className='w-11/12' src={ContactLogo} alt="" />
        </div>
        </div>
    </div>
  )
}

export default Contact