import Box from '../Box';
import { Link } from "react-router-dom";
const Education=({m})=>{
    return(
      <m.div
      initial={{scale: 0}}
      animate={{scale: 1}}
      transition={{duration: 1.5}}
      exit={{scale: 0}}
      className="h-full"
  >
    <Box>
      <Link
          to="/education"
          className="flex  flex-col justify-center lg:justify-around
        h-full w-full p-3 xl:items-center"
      >
        <m.img
            initial={{scale: 0}}
            animate={{scale: 1}}
            transition={{duration: 1.4}}
            exit={{scale: 0}}
            src={'/images/avatar/edu3.png'}
            className="w-9/12  object-contain"
            alt=""
        />
        <div className="">
          {/*<p className="text-subtitle mb-1 text-xs">SHOWCASE</p>*/}
          <p className="text-xl xl:text-2xl">Education</p>
        </div>
      </Link>
    </Box>
  </m.div>
    )
}

export default Education;