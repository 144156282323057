import Box from '../Box';
import { Link } from "react-router-dom";
const Article=({m})=>{
    return(
        <m.div
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{ duration: 1.4 }}
        exit={{ scale: 0 }}
        className="md:col-span-1 col-span-2  xl:order-first"
      >
        <Box>
          <Link
            to="/articles"
            className="flex flex-col justify-center xl:justify-around w-full h-full items-center"
          >
            <m.img
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ duration: 1.4 }}
              exit={{ scale: 0 }}
              src="/images/ar.png"
              alt=""
              className="w-32  lg:w-[160px]"
            />
             <p className="text-subtitle mb-1 text-xs"> Highly rated articles</p>
            <p className="text-center w-full text-xl xl:text-2xl">Articles</p>
          </Link>
        </Box>
      </m.div>
    )
}

export default Article;