import { useCallback } from "react";
import Navbar from "../Components/Navbar";
import { Outlet } from "react-router-dom";
import Particles from "react-particles";
import { loadFull } from "tsparticles";
import { mouseAttraction } from "../constant/partials";
import MouseFollower from "./../Components/MouseFollower";
import Footer from "../Components/Footer";
import {  LoadingContextProvider } from "../contex/Context";


export const MainLayout = () => {
  const particlesInit = useCallback(async (engine) => {
    await loadFull(engine);
  }, []);
  const particlesLoaded = useCallback(async (container) => {
    // await console.log(container);
  }, []);

  // const {pathname}=useRouter()

  return (
    <LoadingContextProvider>
      <div className='cover-home1 hidden md:block'></div>
      <main className="w-full overflow-hidden relative z-[10]  lg:container ">
        <MouseFollower />
        <div className="w-full  h-full top-0 left-0 opacity-40  absolute ">
          <Particles
            options={mouseAttraction}
            init={particlesInit}
            id="tsparticles"
            loaded={particlesLoaded}
          />
        </div>
        <div className="w-full lg:container ">
          <Navbar />
          <div className="min-h-[500px] px-2">
            <Outlet />
          </div>
          <Footer/>
          
        </div>
      </main>
    </LoadingContextProvider>

  );
};
