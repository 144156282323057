
import { FaComputer } from "react-icons/fa6";
import { MdOutlineManageAccounts } from "react-icons/md";
import ProjectTitle from "../Components/Projects/ProjectTitle";
import { motion as m } from "framer-motion";
// import { skills } from "../data";

import { useEffect,useState } from "react";
import { get } from "../service/axiosService";

const Skills = () => {
  const [skills, setSkills] = useState([])
  useEffect(()=>{
    get('skills').then(({data})=>{
  
      setSkills(data.skills)
    })
  },[])
  return (
    <div className="w-full flex flex-col xl:w-11/12 xl:grid gap-8 xl:grid-cols-4 mx-auto">
      <div className="xl:col-span-1 bg-box flex flex-col w-full whitespace-nowrap order-1 xl:order-first  p-8 xl:h-[600px]">
        <m.div 
           initial={{scale:0}}
           whileInView={{scale:1}}
           exit={{scale:0}}
           transition={{duration:1,delay:.5}}
        className="flex items-center gap-4 mb-8">
          <FaComputer size={32} />
          <span className="text-base md:text-xl font-bold">Computer Science</span>
        </m.div>
        <m.div
           initial={{scale:0}}
           whileInView={{scale:1}}
           exit={{scale:0}}
           transition={{duration:1,delay:0.7}}
        className="flex items-center gap-4 mb-8">
          <MdOutlineManageAccounts size={32} />
          <span className="text-base md:text-xl font-bold">Product Manager</span>
        </m.div>
       
      </div>
      <div className="w-full xl:col-span-3 order-first xl:order-1">
        <ProjectTitle title="My Skills" m={m} />
        <div className="bg-box relative h-fit md:h-[505px] p-4 overflow-y-auto">
          <div className="grid  grid-cols-2 lg:grid-cols-3 
          gap-4 gap-y-8
           skillsIcon">
            {skills.map((skill,index)=>(
            <m.div
            key={skill.id}
            initial={{scale:0}}
          whileInView={{scale:1}}
          exit={{scale:0}}
          transition={{duration:1,delay:index/10}}
            className="flex items-center text-sm  gap-2 md:text-lg">
             <i  className={skill.icon}></i>
            <span>{skill.title}</span>
            </m.div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Skills;
