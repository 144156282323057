import { useEffect, useState } from "react";
import ProjectItem from "../Components/ProjectItem";
import ProjectTitle from "../Components/Projects/ProjectTitle";
import {motion as m} from 'framer-motion'
import { get } from "../service/axiosService";

const Projects = () => {
  const [projects, setProjects] = useState([])
  useEffect(()=>{
   get('projects').then(({data})=>{
   setProjects(data.projects)
   }).catch(error=>{
    console.log(error);
   })
  },[])
  return (
    <m.div
    initial={{opacity:0}}
    whileInView={{opacity:1}}
    exit={{opacity:0}}
   
    transition={{duration:1.5,type:"spring"}}
    className="w-full md:container mx-auto ">
      <ProjectTitle m={m} title='All Projects'/>
       {/* <div className="grid  md:grid-cols-2 mb-4 gap-4">
        {projects.slice(0, 2).map((project) => (
        <ProjectItem key={project.id} m={m} project={project}/>
        ))}
      </div>  */}
     
          
        <div className="grid  md:grid-cols-3 xl:grid-cols-4 gap-4  justify-items-center">
          {projects.map((project) => (
            <ProjectItem key={project.id} m={m} project={project}/>
          ))}
        </div>
      
    </m.div>
  );
};

export default Projects;
