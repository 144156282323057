import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { MainLayout } from './layout/MainLayout';
import App from './App';
import About from './Pages/About';
import Projects from './Pages/Projects';
import Skills from './Pages/Skills';
import Certificate from './Pages/Certificate';
import Articles from './Pages/Articles';
import Article from './Pages/Article';
import Contact from './Pages/Contact';
import Education from './Pages/Education';
const router = createBrowserRouter([
  {
    path: "/",
    element: <MainLayout/>,
    children:[
     
      {path:'/',element:<App/>},
      {path:'/about',element:<About/>},
      {path:'/contact',element:<Contact/>},
      {path:'/projects',element:<Projects/>},
      {path:'/skills',element:<Skills/>},
      {path:'/certificate',element:<Certificate/>},
      {path:'/education',element:<Education/>},
      {path:'/articles',element:<Articles/>},
      {path:'/article/:id',element:<Article/>},
    ]
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
 
   <>

   <RouterProvider router={router} />
   </>
 
);

