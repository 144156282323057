import { FaLinkedin, FaInstagram } from "react-icons/fa6";
import { BsWhatsapp } from "react-icons/bs";
import { BiLogoGmail } from "react-icons/bi";
import { Link } from "react-router-dom";
import ExperinceItem from "../Components/Experience/ExperinceItem";
import { motion as m } from "framer-motion";
import { useContext, useEffect, useState } from "react";
import { get } from "../service/axiosService";
import { LoadingContext } from "../contex/Context";

const About = () => {

  const [contacts, setContacts] = useState([])
  const [exps, setExps] = useState([])
  const [info, setInfo] = useState({})
  const {setShow}=useContext(LoadingContext)
  async function getData(){
    try {
      setShow(true)
      const {data}=await get('info')
    setContacts(data.contacts)
      setInfo(data.info)
      setExps(data.exp)
      // console.log(data)
    } catch (error) {
      console.log(error);
    }finally{
      setShow(false)
    }
  }
  useEffect(()=>{
    getData()
  },[])
  return (
    <m.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ duration: 3 }}
    className="grid md:grid-cols-5 lg:grid-cols-4 gap-4  xl:w-10/12 mx-auto">
      <m.div
       initial={{ x:-250  }}
       exit={{ x:250  }}
       whileInView={{ x: 0 }}
       transition={{ duration: 1.2 }}
      className="lg:sticky  top-0 h-screen md:col-span-2 lg:col-span-1">
        <div className="box-grad  flex flex-col sticky-top left-0 items-center gap-6 p-6">
          <img alt="_" src={info.image_url} className="rounded-2xl shadow-md" />
          <p className="text-2xl text-[#0ea5ea] capitalize font-bold">{info.name}</p>
          <p className="text-gray-300 text-sm">@shahrzad-mamourian</p>

          <div className="grid grid-cols-4 gap-2">
            {/* {icons.map((Icon, index) => (
              <div key={index} className="social-link">
                <Icon size={24} />
              </div>
            ))} */}
             <a
                  target="_blank" rel="noreferrer"
                  // href="https://www.linkedin.com/in/shahrzad-mamourian/"
                  href={contacts.find(i=>i.name==="linkedin")?.url}
                  
                  className="social-link">
                    <FaLinkedin color="#0A66C2" size={32} />
                  </a>
                  <a
                  target="_blank" rel="noreferrer"
                  href={contacts.find(i=>i.name==="instagram")?.url}
                  
                  className="social-link">
                    <FaInstagram color="#EA6155" size={32} />
                  </a>
                  <a
                 href={contacts.find(i=>i.name==="whatsapp")?.url}
                   
                  className="social-link">
                    <BsWhatsapp color="#40E15D" size={32} />
                  </a>
                  <a
                    href={`mailto:${contacts.find(i=>i.name==="gmail")?.url}`}
                    target="_blank" rel="noreferrer"
                  
                  className="social-link">
                    <BiLogoGmail color="crimson" size={32} />
                  </a>
          </div>
          <Link to="/contact" className="text-white text-center p-3 text-lg rounded-full bg-[#0ea5ea] w-full">
            Contact Me
          </Link>
        </div>
      </m.div>
      <div className=" md:col-span-3 p-8 text-justify bg-grad text-gray-300 leading-6">
        <m.h1  initial={{ y: -250 }}
        animate={{ y: 0 }}
        transition={{ duration:1 }} exit={{y:0}} className="text-xl mb-8 font-bold">ABOUT ME</m.h1>
        <m.p   initial={{ scale: 0.5 }}
        animate={{ scale: 1 }}
        transition={{ duration:1 }} exit={{scale:0}}className="mb-10">
        {info.info}
        </m.p>
       

        <m.h1  initial={{ y: 150 }}
        animate={{ y: 0 }}
        transition={{ duration:1.1 }} exit={{y:0}} className="text-xl mb-8 font-bold">EXPERIENCE</m.h1>
        {exps.map(x=>(
            <ExperinceItem exp={x} key={x.id}/>
        ))}
      </div>
    </m.div>
  );
};

export default About;
