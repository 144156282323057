import React from 'react'
import Box from '../Box';
import { Link } from "react-router-dom";
import { LuDownload } from "react-icons/lu";

const Cetificate = ({m}) => {
  return (
    <m.div
    initial={{ scale: 0 }}
    animate={{ scale: 1 }}
    transition={{ duration: 1.5 }}
    exit={{ scale: 0 }}
    className="h-full"
  >
    <Box>
      <Link
        to="/certificate"
        className="flex  flex-col justify-center 
      lg:justify-around h-full w-full  xl:items-center"
      >
        <m.img
          animate={{ scale: 1 }}
          initial={{ scale: 0 }}
          transition={{ duration: 1.4 }}
          exit={{ scale: 0 }}
          src="/images/avatar/certif1.png"
          className="h-40 w-60 object-contain  mt-8 lg:mt-0 mx-auto lg:mx-0 rounded-xl mb-4"
          alt=""
        />
        <div className="">
          <p className="text-subtitle mb-1 text-xs">
            MORE ABOUT ME
          </p>
          <p className="text-xl xl:text-2xl">Certificate</p>
        </div>
      </Link>
    </Box>
  </m.div>
  )
}

export default Cetificate
