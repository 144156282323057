import ScrollToTop from "react-scroll-to-top";
import ScrollTop from "./ScrollTop";

const ArticleB = () => {
  return (
    <>
    <ScrollToTop smooth 
     viewBox="0 0 0 0"
     style={{background:"transparent",boxShadow:'0'}}
    component={<ScrollTop/>} />
  
    <div className="flex-col-start gap-8 w-full lg:w-10/12 mx-auto bg-box p-4 md:p-8  overflow-auto">
            <div className="text-large">Aspect Oriented Programming</div>

            <div className="text-small">
            Programming paradigms include AOP (Aspect-oriented programming) and OOP (Object-oriented programming).<br/> As the name implies, OOP concentrates on modeling issues using actual objects and their behavior, whereas AOP works with decomposing programs into crosscutting specific problems.
AOP in .NET introduces aspect-oriented programming to .NET developers and offers helpful advice on how to
 apply this method to your daily coding to maximize its advantages. <br />
AOP is a paradigm for programming that tries to make programs more modular by separating side effects
 from code modifications. Your app must perform this task in several locations, including logging, caching,
  and other areas.
Without clogging up your code, these behaviors not essential to the business logic can be included.
            </div>
            <div className="text-medium">
            A Guide to Utilizing AOP in C#
            </div>
            <div className="text-small">

            AOP (Aspect Oriented Programming) has been discussed extensively online; however, the publications need to explain its use in C#. <br />
By enabling the separation of cross-cutting issues, the programming paradigm known as AOP (Aspect-oriented programming) attempts to promote modularity. It accomplishes this without changing the code by adding behavior to existing code (a suggestion). <br />
Cross-cutting software problems include things like logging and monitoring, caching, data validation, etc. These issues prevent the system from being cleanly dissected during design or implementation, which typically results in code duplication and dispersion. <br />

            </div>
            <div className="text-medium">
            Advancing Software Quality with AOP: The Benefits of Modularization and Consistent Implementation in C#
            </div>
            <div className="text-small">
           <strong> Employing AOP would unquestionably improve software quality in a variety of ways, including:</strong> <br/>
• Clearly defined roles for each module: AOP provides improved modularization by consolidating code that addresses the same issue into a single module, preventing the duplication of the cross-cutting problems. <br/>
 • Consistent implementation: AOP offers a consistent performance by having each element dealt with just once, in contrast to traditional implementations of crosscutting issues, which are glaringly inconsistent. <br/>
• Increased reusability: AOP separates fundamental issues from crosscutting ones, allowing for better mixing and matching and enhancing overall reusability in both modules. <br/>
• Better skill transfer: The AOP ideas are adaptable and reusable. <br/>
• System-wide policy enforcement: By developing reusable components, AOP enables programmers to impose various agreements and offer instructions for adhering to "best" practices. <br/>
• Improved software quality testing through virtual mock objects: The AOP application uses mock objects to simulate the actual world more accurately. <br/>
• Non-intrusive what-if analysis: Unlike non-AOP techniques, AOP does not waste time or resources by doing what-if analysis repeatedly before altering system behavior to see whether the functionality is required. <br/>
            </div>
            <div className="text-medium">
            Simplifying Code Management: Understanding the Need for AOP in C#
            </div>
            <div className="text-small">
            To assist in concentrating the code for these cross-cutting issues, prevent code duplication and scattering, and therefore uphold the single responsibility concept, it is crucial to master and use AOP . <br/>
All cross-cutting code is transferred to a different module, enhancing modularity and facilitating maintenance. Okay, so that was a theoretical description. Now that we have good techniques like "Object Oriented Programming" and "Procedural Oriented Programming," let's attempt to grasp why we need AOP.

            </div>
            <div className="text-medium">
            .NET's primary AOP Techniques
            </div>
            <div className="text-small">
            To modify a.NET program's behavior or to weave a.NET program, there are essentially two methods: <br/>
· Compile-Time Weaving: Before deployment, the app is altered during the build process on the development computer. <br/>
· Runtime Weaving: After deployment, the app is altered while it is being used.
            </div>

            <div className="text-medium">
            Conclusion
            </div>
            <div className="text-small">
            The programming methodology known as AOP aims to achieve greater code modularization, which was introduced in response to the highlighted need for higher-quality software. These can be summed up as the sincere need for higher-quality software. Overall, AOP can effectively improve the maintainability, modularity, and reusability of your C# and ASP.NET code.
            </div>
            <div className="text-medium">Sources:</div>
            <div className="text-small text-blue-500">
                <a target='_blank' className='block' href="https://thesai.org/Downloads/Volume4No9/Paper_4-Review_on_Aspect_Oriented_Programming.pdf">
                https://thesai.org/Downloads/Volume4No9/Paper_4-Review_on_Aspect_Oriented_Programming.pdf
                </a>
                <a target='_blank' className='block' href="https://www.postsharp.net/aop.net">
                https://www.postsharp.net/aop.net
                </a>
            </div>
    </div>
     </>
  )
}

export default ArticleB