import Box from '../Box';
import { FaLinkedin, FaInstagram } from "react-icons/fa6";


import { BsWhatsapp } from "react-icons/bs";
import { BiLogoGmail } from "react-icons/bi";
import { Link } from "react-router-dom";
const Contacts=({m,contacts})=>{
    return(
      <m.div
      initial={{ scale: 0 }}
      animate={{ scale: 1 }}
      transition={{ duration: 1.5 }}
      exit={{ scale: 0 }}
      className="md:col-span-1 col-span-2"
    >
      <Box>
        <div className="flex flex-col justify-around w-full h-full items-center">
          <div className="flex bg-grad justify-around mb-2 py-4 rounded-xl w-full">
            <m.a
              target="_blank"
              rel="noreferrer"
              // href="https://www.linkedin.com/in/shahrzad-mamourian/"
              href={contacts.find((i) => i.name === "linkedin")?.url}
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ duration: 2 }}
              exit={{ scale: 0 }}
              className="bg-grad p-4 rounded-full"
            >
              <FaLinkedin color="#0A66C2" size={32} />
            </m.a>
            <m.a
              target="_blank"
              rel="noreferrer"
              href={contacts.find((i) => i.name === "instagram")?.url}
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ duration: 2 }}
              exit={{ scale: 0 }}
              className="bg-grad p-4 rounded-full"
            >
              <FaInstagram color="#EA6155" size={32} />
            </m.a>
            <m.a
              href={contacts.find((i) => i.name === "whatsapp")?.url}
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ duration: 2 }}
              exit={{ scale: 0 }}
              className="bg-grad p-4 rounded-full"
            >
              <BsWhatsapp color="#40E15D" size={32} />
            </m.a>
            <m.a
              href={`mailto:${
                contacts.find((i) => i.name === "gmail")?.url
              }`}
              target="_blank"
              rel="noreferrer"
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ duration: 2 }}
              exit={{ scale: 0 }}
              className="bg-grad p-4 rounded-full"
            >
              <BiLogoGmail color="crimson" size={32} />
            </m.a>
          </div>
          <div className="">
            <p className="text-subtitle mb-1 text-xs">STAY WITH ME</p>
            <p className=" text-base">Contact</p>
          </div>
        </div>
      </Box>
    </m.div>
    )
}

export default Contacts;