import { useContext } from "react";
import { Link } from "react-router-dom";
import { LoadingContext } from "../contex/Context";

const Logo = () => {
  const {show}=useContext(LoadingContext)
  return (
    <Link
      to="/"
      style={{ letterSpacing: "6px" }}
      className={`text-xl ${show && 'loader'} uppercase cursor-pointer font-bold tracking-widest`}
    >
      <span className="text-blue-600 letter font-semibold text-4xl">S</span>

      <span class="letter">h</span>
      <span class="letter">a</span>
      <span class="letter">h</span>
      <span class="letter">r</span>
      <span class="letter">z</span>
      <span class="letter">a</span>
      <span class="letter">d</span>
    </Link>
  );
};

export default Logo;
