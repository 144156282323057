const list_intrest=[
    {title:'music',id:1},
    {title:"movie",id:2},{title:"photography",id:3},
    {title:"Swimming",id:4},{title:"travel",id:5},
    {title:"coding",id:6},
]
const Intrests=({m})=>{
    return(
        <m.div
        initial={{ y: 150 }}
            exit={{ y: 0 }}
            animate={{ y: 1 }}
            transition={{ duration: 1.5 }}
        className='grid grid-cols-6 items-center rounded-lg lg:h-64 pb-4  box-grad'>
              <div className="col-span-6 lg:col-span-1 text-white leading-5 p-2 h-full">
                <div className=" h-full flex gap-3 justify-center items-center flex-col">
                    <img src="/images/intrest.png" className='h-36 w-40 aspect-square' alt=""/>
                    {/*<VscFeedback size={30}/>*/}
                    <p className='text-center text-xl text-sky-500'>
                        <span className='text-[#AA6CF8]'>My</span>Intrests
                    </p>
                   
                </div>

            </div>  
            <div className='col-span-6 lg:col-span-5'>
                <div className='grid grid-cols-3 lg:grid-cols-6 gap-1'>
                    {list_intrest.map((intrest)=>(
                        <div key={intrest.id} className='flex flex-col  items-center gap-2'>
                    <div className='box-grad p-1 w-28 shadow-md h-28 ' >
                            <img className='  ' src={`/images/intrest/${intrest.title}.png`} alt=''/>
                    </div>
                    <p className='text-base font-bold capitalize'>{intrest.title}</p>
                    </div>
                ))}
                </div>
            </div>
        </m.div>
    )
}

export default Intrests;