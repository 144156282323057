import Box from '../Box';
import { Link } from "react-router-dom";
const Skill=({m})=>{
    return(
      <m.div
      initial={{ scale: 0 }}
      animate={{ scale: 1 }}
      transition={{ duration: 1.5 }}
      exit={{ scale: 0 }}
     className="col-span-2  order-first "
   >
     <Box>
       <Link
         to="/skills"
         className="flex justify-between gap-8  flex-col w-full"
       >
         <div className="grid grid-cols-5  justify-center items-center w-full gap-1 md:gap-8">
           <m.img
            initial={{ y: 150 }}
            exit={{ y: 0 }}
            animate={{ y: 1 }}
            transition={{ duration: 1.5 }}
             src="/images/c.png"
             className="w-24 bg-grad rounded-full"
             alt=""
           />
           <m.img
             initial={{ y: 150 }}
             exit={{ y: 0 }}
             animate={{ y: 1 }}
             transition={{ duration: 1.5 }}
             src="/images/asp.png"
             className="w-24 bg-grad rounded-full "
             alt=""
           />
           <m.img
              initial={{ y: 150 }}
              exit={{ y: 0 }}
              animate={{ y: 1 }}
              transition={{ duration: 1.5 }}
             src="/images/sql.png"
             className="w-24 bg-grad rounded-full"
             alt=""
           />
           <m.img
             initial={{ y: 150 }}
             exit={{ y: 0 }}
             animate={{ y: 1 }}
             transition={{ duration: 1.5 }}
             src="/images/js.png"
             className="w-24 p-2 bg-grad rounded-full"
             alt=""
           />

           <m.img
             initial={{ y: 150 }}
             exit={{ y: 0 }}
             animate={{ y: 1 }}
             transition={{ duration: 1.5 }}
             src="/images/html.png"
             className="w-24 bg-grad rounded-full"
             alt=""
           />
         </div>
         <div className="ml-12 mt-8">
           <p className="text-subtitle mb-1 text-xs">SPECIALIZATION</p>
           <p className=" text-2xl"> Skills</p>
         </div>
       </Link>
     </Box>
   </m.div>
    )
}

export default Skill;