import React from "react";
import { Link } from "react-router-dom";
import ProjectTitle from "../Components/Projects/ProjectTitle";
import { motion as m } from "framer-motion";
import { useEffect, useState } from "react";
import { get } from "../service/axiosService";
import { FaUsers } from "react-icons/fa";

const Articles = () => {
  const [articles, setArticles] = useState([]);
  const [publish, setPublish] = useState([]);
  useEffect(() => {
    get("articles").then(({ data }) => {
      setArticles(data.articles);
      setPublish(data.publish);
    });
  }, []);
  return (
    <>
      <ProjectTitle title="Articles" m={m} />
      <div className="grid grid-cols-1 px-2 md:grid-cols-2 xl:grid-cols-3 relative gap-8 mt-16">
        {articles.map((article) => (
          <m.div
            key={article.id}
            initial={{ scale: 0 }}
            whileInView={{ scale: 1 }}
            transition={{ duration: 0.7, delay: 0.3, type: "tween" }}
            exit={{ scale: 0 }}
          >
            <Link
              to={`/article/${article.id}`}
              className="bg-box block h-64 p-8"
            >
              <p className="text-2xl h-24 mb-8 leading-10">{article.title}</p>
              <p className="text-gray-500 mb-1">{article.date}</p>
              <p className="text-blue-500">{article.author}</p>
            </Link>
          </m.div>
        ))}
      </div>
      <br />
      <ProjectTitle title="Publish Articles" m={m} />
      <div className="grid grid-cols-1 px-2 md:grid-cols-2 xl:grid-cols-3 relative gap-8 mt-16">
        {publish.map((article) => (
          <m.div
            key={article.id}
            initial={{ scale: 0 }}
            whileInView={{ scale: 1 }}
            transition={{ duration: 0.7, delay: 0.3, type: "tween" }}
            exit={{ scale: 0 }}
          >
            <a href={article.link}
              
              className=" bg-[#0c1322]  border-[#222F43] border 
            block  p-8"
            >
              <p className="text-xl mb-3 capitalize leading-10">
                {article.title}
              </p>
              <div className="flex justify-between">
                <div className="">
                  <p>{article.subject}</p>
                  <p className="text-gray-500 mb-1">{article.year}</p>
                </div>
                <div className="">
                  {article.is_submitted && <p className="bg-slate-800 text-emerald-500 mb-3 p-2">Submitted</p>}
                  <p className="bg-slate-800  uppercase p-2">{article.type}</p>
                </div>
              </div>
              <div className="flex flex-wrap items-center gap-2" >
                <FaUsers size={20} />
                <p>
                  Shahrzad Mamourian
                </p>
                {article.authors.map((author) => (
                  <a href={author.profile} target="_blank" key={author.id} className="text-blue-500">{author.name}</a>
                ))}
              </div>
            </a>
          </m.div>
        ))}
      </div>
    </>
  );
};

export default Articles;
